import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCountries = createAsyncThunk("countries", async () => {
  try {
    const { data }  = await axios.get("https://api.defitaxcalc.io/countries/",{
      headers:{
        "x-token-defi":"fad8f769-87a8-4c30-b418-835e406af4e3"
      }
    });
    return data;
  } catch (err) {
    console.log(err);
  }
});

