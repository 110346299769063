import React, {useEffect} from 'react';
import './CustomerList.css';
import Skeleton from '@mui/material/Skeleton';
import {Table} from './style'

const CustomerListLoader = () => {
  return (
    <Table>
      <thead>
        <tr>
          {[...Array(4)].map((item, index) => <th key={index}> <Skeleton animation="wave" /></th>)}
        </tr>
      </thead>
      <tbody>
        {[...Array(5)].map((item, index) => <tr key={index}>
          {[...Array(4)].map((item, index) => <td key={index}> <Skeleton animation="wave" /></td>)}
        </tr>)}
      </tbody>
    </Table>
  );
};

export default CustomerListLoader;
