import React, { Component } from 'react';
import "./Login.css";
import logo from "../../assets/img/Logo.png";
import { GoogleLogin } from "react-google-login";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import eye from "../../assets/img/eye.png";
import arrow from "../../assets/img/Arrow.png";
import { initLoginState } from "../../constants/initStates";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import fb from "../../assets/img/fb.png";
import { useDispatch } from "react-redux";
import { loginUser, resetPassword } from "../../api/userAPI";
import { getCountries } from "../../api/others";
import { getCustomersList } from "../../api/customerAPI";

const Login = () => {



  const [login, setLogin] = useState(initLoginState);
  const [show, setShow] = useState(false);


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCountries())
  }, [])

  const navigate = useNavigate()

  const onLoginSuccess = (res) => {
    console.log("Login Success", res);
  };
  const onFailureSuccess = (res) => {
    console.log("Failure Success", res);
  };

  const toggleShow = (e) => {
    e.preventDefault();
    setShow((prevState) => !prevState);
  };

  const responseFacebook = (response) => {
    console.log("res", response);
  }

  const componentClicked = (data) => {
    console.warn(data)

  }
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(login)
    dispatch(loginUser(login))
      .then((originalPromiseResult) => {

        if (originalPromiseResult.meta.requestStatus == "fulfilled") {
          window.sessionStorage.setItem("email", login.email);
          window.sessionStorage.setItem("session_id", originalPromiseResult.payload.content.session_id);
          window.sessionStorage.setItem("firstname", originalPromiseResult.payload.content.firstname);
          window.sessionStorage.setItem("lastname", originalPromiseResult.payload.content.lastname);
          window.sessionStorage.setItem("country", originalPromiseResult.payload.content.country);
          window.sessionStorage.setItem("user_type", originalPromiseResult.payload.content.user_type);
          if (originalPromiseResult.payload.content.force_chg_pwd) {
            navigate("/changepassword", { state: originalPromiseResult.payload.content });
          } else if (originalPromiseResult.payload.content.user_type === "Accountant") {
            dispatch(getCustomersList({ email: login.email, session_id: originalPromiseResult.payload.content.session_id }));
            navigate("/clients", { state: originalPromiseResult.payload.content })
          } else {
            navigate("/records", { state: originalPromiseResult.payload.content })
          }

        } else if (originalPromiseResult.meta.requestStatus == "rejected") {
          alert(originalPromiseResult.payload.content)
        }

      })
      .catch((rejectedValueOrSerializedError) => {
        alert("Login failed")
      })
  };

  const onReset = () => {
    dispatch(resetPassword(login))
  }

  const onLoginChange = (e) => {
    const { id, value } = e.target;
    setLogin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  return (
    <div className="wrapper_login">
      <div className="login_div">
        <div className="arrow_div">
          {" "}
          <a className="back" href="https://defitaxcalc.io/" >
            {" "}
            <img src={arrow} alt="" /> Back
          </a>
        </div>

        <div className="logo_div">
          <figure className="logo">
            <img src={logo} alt="logo" />
          </figure>
        </div>
        <div className="line">
        </div>
        <form onSubmit={onSubmit} className="form_login">
          <input
            type="email"
            className="input_email"
            placeholder="Email"
            id="email"
            required
            onChange={onLoginChange}
          />
          <input
            onChange={onLoginChange}
            id="password"
            required
            type="password"
            className="input_password"
            placeholder="Password"
          />

          <button type="submit" className="submit_btn">
            Sign In
          </button>
        </form>
        <div className="separate"></div>
        <a href="https://defitaxcalc.io/contact-us/" target="_top" rel="noreferrer" className="btn_create submit_btn" style={{ background: "#fff", color: "#000", border: "1px solid #000" }}>Create New Account</a>
      </div>
    </div>
  );
};

export default Login;
