import React from 'react'
import { css  } from '@emotion/react'
import TransactionsListItem from './TransactionsListItem'
import { getSortedHeaders } from './TransactionsUtils';
import { Table } from './style';

const TransactionsList = ({ headers, rows = [],lastModified }) => {

 
  return (
    <div className='table-header-fixed'>
      <hr />
      <Table>
        <thead>
          <tr>
            {getSortedHeaders(headers).map((item, index) => (
              <th key={index}>{item.name}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => <TransactionsListItem key={`${item.txn_id}${lastModified}`} headers={headers}lastModified={lastModified} data={{ isEdited: false, ...item }} />)}
        </tbody>
      </Table>
      </div>

  )
}

export default TransactionsList