import React from 'react'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import { deleteReport } from "../../api/exchangeAPI";
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getSelectedCustomer } from "../../features/CustomerSlice";
import { useDispatch, useSelector } from "react-redux";


const ExchangesListItem = ({ data }) => {
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(getSelectedCustomer);
  const deleteSelectedReport = (file_uploaded, exchange) => {
    let user_info = {
      file_uploaded,
      exchange,
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer,
      "email": window.sessionStorage.getItem("email"),
      "session_id": window.sessionStorage.getItem("session_id")
    };
    dispatch(deleteReport(user_info));
  };

  const { exchange, file_uploaded, date_uploaded, number_of_txns, start_date, end_date, has_errors, error_message_codes } = data;
  return (
    <tr>

      <td>{exchange}</td>
      <td>{file_uploaded}</td>
      <td>{date_uploaded}</td>
      <td>{number_of_txns}</td>
      <td>{start_date}</td>
      <td>{end_date}</td>
      <td>{has_errors ? <Tooltip title={error_message_codes.map(({ message, error_code }, index) => { return <div style={{ fontSize: '14px' }} key={index}>{`${error_code}: ${message}`}<br /></div> })}><ErrorIcon style={{ color: 'red' }} /></Tooltip> : <DoneAllIcon style={{ color: 'green' }} />} </td>
      <td>
        <IconButton className="action-button" onClick={deleteSelectedReport.bind(this, file_uploaded, exchange)} aria-label="delete">
          <DeleteOutlinedIcon style={{ color: '#ccc' }} />
        </IconButton></td>
    </tr>
  )
}

export default ExchangesListItem