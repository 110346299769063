import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import { getSortedHeaders } from './TransactionsUtils';
import ManageTransaction from '../ManageTransaction/ManageTransaction';
import { getSelectedCustomer } from "../../features/CustomerSlice";
import { updateTransaction, deleteTransaction } from "../../api/transactionAPI";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { resetTransactionsList } from "../../features/TransactionSlice";

import "./TransactionsList.css";


const TransactionsListItem = ({ data, headers, lastModified }) => {


  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState({ isEdited: false });
  const selectedCustomer = useSelector(getSelectedCustomer);

  useEffect(() => {
    setTableRow(data);
  }, [lastModified, data])


  const updateRowData = (data) => {
    setTableRow(data);
  }

  const submitUpdateRequest = () => {

    const { isEdited, ...payload } = { ...tableRow };
    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      "body": { "update": [{ ...payload }] },
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer,
      "session_id": window.sessionStorage.getItem("session_id")
    };
    dispatch(updateTransaction(user_info));
  }



  const deleteTxn = (txn) => {
    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      "transaction": [txn],
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer,
      "session_id": window.sessionStorage.getItem("session_id")
    };

    dispatch(deleteTransaction(user_info));
  }
  return (
    <>
      <tr>
        {getSortedHeaders(headers).map((item, index) => <td key={`${index}${lastModified}`}>
          {item.key === "review" ? tableRow[item.key] ? <Tooltip title={<span style={{ fontSize: '14px' }}>{tableRow[item.key]}</span>}><InfoIcon style={{ color: '#1976d2' }} /></Tooltip> : ""
            : item.key === "is_valid"
              ? tableRow[item.key] ? <DoneAllIcon style={{ color: 'green' }} /> : <ErrorIcon style={{ color: 'red' }} />
              : tableRow[item.key] || ""}
        </td>)}
        <td>
          <div style={{
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
          }}>

            <ManageTransaction data={tableRow} headers={headers} setData={updateRowData} mode={'Edit'} />
            <IconButton className="action-button" onClick={() => { deleteTxn(tableRow['txn_id']) }} aria-label="delete">
              <DeleteOutlinedIcon style={{ color: '#ccc' }} />
            </IconButton>
            {tableRow.isEdited && (<Button onClick={submitUpdateRequest}>Submit</Button>)}

          </div>
        </td>

      </tr>
    </>
  )
}

export default TransactionsListItem