const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const initLoginState = {
  email: "",
  password: "",
  timezone:toString(timezone)
};

export const initSignupState = {
  "firstname": "",
  "lastname": "",
  "country": "",
  "email": "",
  "password": "",
  "company": "DefiTaxCalc",
  "usertype": ""
};


export const initPurchasePlanState = {
  "firstname": "",
  "lastname": "",
  "email": "",
  "country": "",
  "password": "",
  "plan": ""
};


export const initTransactionState = {
  txn_id: "3fa85f64-57",
  date: "2022-06-02",
  platorm : "string",
  source: "string",
  credit: "string",
  debit: "string",
  type: "string",
  fees: "string",
  fees_type: "string",
  is_valid: true,
  desc: "string"
}