import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import close from "../../assets/img/close.png";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { getSortedHeaders } from '../TransactionsList/TransactionsUtils';
import { getSupportedTransactionItems } from "../../features/TransactionSlice";
import { useDispatch, useSelector } from "react-redux";
import { transactionSchema } from "../../schema/transactionSchema";
import "./ManageTransaction.css";
import "react-datepicker/dist/react-datepicker.css";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "2px solid #000",
    borderRadius: "16px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15);",
    pt: 2,
    px: 4,
    pb: 3,
};



export default function ManageTransaction({ data, headers, setData, mode }) {


    const dispatch = useDispatch();

    const { supportedCoins = [],
        supportedTransactions = [],
        supportedExchanges = [] } = useSelector(getSupportedTransactionItems);


    const [date, setDate] = React.useState(null);
    const [time, setTime] = React.useState(moment());
    const [open, setOpen] = React.useState(false);
    const [isEdited, setIsEdited] = React.useState(false);
    const [updatedData, setUpdatedData] = React.useState({});



    React.useEffect(() => {

        setIsEdited(data.isEdited);
        const txndata = { ...data };
        if (txndata.tdt) {

            const txnDate = txndata.tdt.toString().split(" ");
            const date = txnDate[0].toString().split("-");
            setDate(new Date(date[0], date[1] - 1, date[2]));
            const [hh, mm, ss] = txnDate[1].split(":");
            setTime(moment().hours(hh).minutes(mm).seconds(ss));
        } else {
            setDate(null);
            setTime(moment());
        }
        setUpdatedData({ ...txndata });


    }, [open]);


    const handleChange = ({ target }) => {
        const { name, value } = target;

        setIsEdited(true);

        setUpdatedData(data => { return { ...data, [name]: value } })
    };


    const handleOpen = () => {
        setOpen(true);

    };

    const handleClose = () => {
        setDate(null);
        setTime(moment());
        setOpen(false);
        setUpdatedData({});
        setOpen(false);
    };


    const handleChangeDate = (date) => {
        setDate(date);
        setIsEdited(true);
    }

    const handleChangeTime = (value) => {
        setTime(value);
        if (date) {
            setIsEdited(true);
        }
    }

    const update = () => {

        const latestUpdatedData = { ...updatedData };
        const previousData = JSON.parse(JSON.stringify(data));
        let previousRowData = { ...data };


        getSortedHeaders(headers).forEach(({ key }) => {
            if (["tty", "indefi", "incur", "oudefi", "oucur"].indexOf(key) > -1 && (previousRowData.hasOwnProperty(key) || latestUpdatedData[key])) {
                if (Array.isArray(latestUpdatedData[key])) {
                    previousRowData[key] = latestUpdatedData[key][0];
                }
                else {
                    previousRowData[key] = latestUpdatedData[key];
                }

            } else if (key === "tdt") {
                const [hh,mm,ss] = [time.hours(),time.minutes(),time.seconds() ];
                if (date) {
                    const formattedDateTime = `${moment(date).format('YYYY-MM-DD')} ${hh < 10 ? "0"+hh : hh}:${mm < 10 ? "0"+mm : mm}:${ss < 10 ? "0"+ss : ss}`;
                    previousRowData[key] = formattedDateTime;
                    console.log(formattedDateTime);
                }
                

            } else if (previousRowData.hasOwnProperty(key) || latestUpdatedData[key]) {
                previousRowData[key] = latestUpdatedData[key]
            }
        });


        Object.keys(transactionSchema).forEach(key => {
            if (previousRowData.hasOwnProperty(key)) {
                if (transactionSchema[key] === "number") {
                    previousRowData[key] = Number(previousRowData[key]);
                } else {
                    previousRowData[key] = previousRowData[key];
                }
            }
        });

        setData({ ...previousData, ...previousRowData, isEdited });

        setOpen(false);
    };

    return (
        <div>
            {mode === 'Create' ? <Button
                sx={{
                    background:
                        "linear-gradient(219.14deg, #f9c050 3.52%, #ea1c1c 149.82%)",
                    borderRadius: "4px",
                    padding: "8px 50px",
                    border: "none",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "white",
                }}
                onClick={handleOpen}
            >
                {`${mode} Transaction`}
            </Button>
                : <IconButton className="action-button" onClick={handleOpen} aria-label="edit">
                    <EditOutlinedIcon style={{ color: '#ccc' }} />
                </IconButton>}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 848, height: 'calc(100% - 100px)', overflow: 'auto' }}>
                    <div className="modal_flex">
                        {" "}
                        <h2 id="parent-modal-title">{mode} Transaction</h2>
                        <button className="btn_close_modal" onClick={handleClose}>
                            <img src={close} alt="" />
                        </button>
                    </div>

                    <div className="grey_line_modal" style={{ marginBottom: 20 }}></div>
                    <div className="form_modal" style={{ height: 'calc(100% - 120px)', padding: '20px 10px 10px 10px', overflow: 'auto' }}>
                        <Grid container spacing={2}>
                            {
                                getSortedHeaders(headers).map((item, index) => {
                                    if (["tdt"].indexOf(item.key) > -1) {
                                        return <React.Fragment key={item.key}>
                                            <Grid className="date-picker" item xs={6}>
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        selected={date}
                                                        dateFormat="yyyy-MM-dd"
                                                        onChange={handleChangeDate}
                                                        showYearDropdown
                                                        placeholderText="Select Date"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <TimePicker
                                                            ampm={false}
                                                            openTo="hours"
                                                            views={['hours', 'minutes', 'seconds']}
                                                            inputFormat="HH:mm:ss"
                                                            mask="__:__:__"
                                                            label="Time"
                                                            value={time}
                                                            onChange={handleChangeTime}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                    else {
                                        return <Grid key={item.key} item xs={6}>
                                            {
                                                ["tty", "indefi", "incur", "oudefi", "oucur"].indexOf(item.key) > -1 ?
                                                    <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                                                        <InputLabel id={item.key + "_label"}>{item.name}</InputLabel>
                                                        <Select
                                                            labelId={item.key}
                                                            value={updatedData[item.key] || ""}
                                                            onChange={handleChange}
                                                            name={item.key}
                                                            id={item.key}
                                                            label={item.name}
                                                        >
                                                            <MenuItem value="">Select</MenuItem>
                                                            {(item.key === "indefi" || item.key === "oudefi") && supportedExchanges.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                                            {(item.key === "oucur" || item.key === "incur") && supportedCoins.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                                            {item.key === "tty" && supportedTransactions.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}

                                                        </Select>
                                                    </FormControl> : <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                                                        <TextField
                                                            name={item.key}
                                                            id={item.key}
                                                            label={item.name}
                                                            placeholder={item.name}
                                                            value={updatedData[item.key]}
                                                            onChange={handleChange}
                                                            disabled={item.key === "is_valid" || (item.key === "txn_id" && mode === "Create")}
                                                            type={["fvqty", "fefvqty", "is_valid", "ouqty", "inqty", "txn_id"].indexOf(item.key) > -1 ? "number" : "text"}
                                                        />
                                                    </FormControl>
                                            }

                                        </Grid>
                                    }
                                })
                            }
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            sx={{ marginTop: '15px' }}>

                            <button onClick={handleClose} className="std_button" style={{ background: "#fff", color: "#000", border: "1px solid #000" }}>
                                Cancel
                            </button>

                            {time.isValid() && <button
                              type="button"
                              className="std_button"
                                onClick={update}
                            >
                                {mode === "Create" ? 'Create' : 'Update'}
                            </button>}

                        </Grid>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
