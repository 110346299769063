import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import CryptoGains from "./pages/CryptoGains/CryptoGains";
import Dashboard from "./pages/Dashboard/Dashboard";
import Exchanges from "./pages/Exchanges/Exchanges";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Home from "./pages/Home/Home";
import Invertory from "./pages/Invertory/Invertory";
import Login from "./pages/Login/Login";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Signup from "./pages/Signup/Signup";
import Transactions from "./pages/Transactions/Transactions";
import Customer from "./pages/Customer/Customer";
import CustomerAdd from "./pages/CustomerAdd/CustomerAdd";
import PlanPurchase from "./pages/PlanPurchase/PlanPurchase";
import Misc from "./pages/Misc/Misc";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/confirmemail" element={<ConfirmEmail />}></Route>
        {/* <Route path="/forgotpassword" element={<ForgotPassword/>}></Route> */}
        <Route path="/changepassword" element={<ChangePassword/>}></Route> 
        {/* <Route path="/dashboard" element={<Dashboard/>}></Route> */}
        <Route path="/records" element={<Exchanges />}></Route>
        <Route path="/transactions" element={<Transactions />}></Route>
        <Route path="/cryptogains" element={<CryptoGains />}></Route>
        {/* <Route path="/invertory" element={<Invertory />}></Route> */}
        <Route path="/clients" element={<Customer />}></Route>
        <Route path="/planpurchase" element={<PlanPurchase />}></Route>
        <Route path="/customeradd" element={<CustomerAdd />}></Route>
        <Route path="/misc" element={<Misc />}></Route>
        <Route path="*" element={<Login />} ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
