import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import Box from '@mui/material/Box';
import { Navigate } from "react-router-dom";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import "./CryptoGains.css";
import reverse from "../../assets/img/back.png";
import { getGains, emailGainsReport } from "../../api/cryptoGainsAPI";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCustomer } from "../../features/CustomerSlice";
import { getGainsReport, resetGainsReport, getSendGainsReport, resetEmailGainsReport } from "../../features/CryptoGainsSlice";

import CryptoGainsLoader from "./CryptoGainsLoader";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from './style'

const CryptoGains = () => {

  const dispatch = useDispatch();

  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
    "user_type": window.sessionStorage.getItem("user_type")
  };

  const [gainsReport, isSuccess, isError, isLoading] = useSelector(getGainsReport);
  const [sendGainsReport, emailIsSuccess, emailIsError, emailIsLoading] = useSelector(getSendGainsReport);


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mode, setMode] = React.useState('HIFO');
  const selectedCustomer = useSelector(getSelectedCustomer);

  const [isValidDate, setIsValidDate] = React.useState(false);


  const setDate = (date, type) => {
    if (type === 'START_DATE') {
      setStartDate(() => {
        setIsValidDate(moment(date).isValid() && moment(endDate).isValid());
        return date;
      });
    } else {
      setEndDate(() => {
        setIsValidDate(moment(date).isValid() && moment(startDate).isValid());
        ; return date
      });
    }
    dispatch(resetGainsReport());
    dispatch(resetEmailGainsReport());
  }
  const handleChange = (event) => {
    setMode(event.target.value);
    dispatch(resetGainsReport());
    dispatch(resetEmailGainsReport());
  };

  React.useEffect(() => {

    return () => {
      dispatch(resetGainsReport());
      dispatch(resetEmailGainsReport());
    }
  }, []);


  const showGains = () => {
    dispatch(resetGainsReport());
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');


    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      mode,
      "session_id": window.sessionStorage.getItem("session_id"),
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer
    };
    dispatch(getGains(user_info));
    dispatch(resetEmailGainsReport());
  }


  const sendEmailGainsReport = () => {
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');


    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      mode,
      "session_id": window.sessionStorage.getItem("session_id"),
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer
    };
    dispatch(emailGainsReport(user_info));

  }



  const getSendReportStatus = () => {
    if (emailIsLoading) {
      return <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="info">Emailing Report... </Alert>
      </Stack>
    }
    if (emailIsError || emailIsSuccess) {
      return <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={emailIsError ? "error" : "success"}>{sendGainsReport.content ? sendGainsReport.content.msg : sendGainsReport.detail ? "Invalid Request" : JSON.stringify(sendGainsReport)}</Alert>
      </Stack>
    }
    return "";
  }


  const getDisplayRecords = () => {
    if (isLoading) { return <CryptoGainsLoader /> }
    if (isError) {
      return <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{gainsReport.content ? gainsReport.content.msg : gainsReport.detail ? "Invalid Request" : JSON.stringify(gainsReport)}</Alert>
      </Stack>
    }
    if (isSuccess && gainsReport.nature_of_income) {
      if (Object.keys(gainsReport.nature_of_income).length > 0) {
        return <Table>
          <thead>
            <tr>
              <th className="th">Nature of income</th>
              <th className="th">Amount ( {gainsReport?.currency} )</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(gainsReport.nature_of_income).map((key, index) => <tr key={key}>
              <td>{key}</td>
              <td>{gainsReport.nature_of_income[key]}</td>
            </tr>)}
          </tbody>
        </Table>
      } else {
        return <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="info">No Records Found</Alert>
        </Stack>;
      }
    }

    if (isSuccess && (gainsReport.msg || gainsReport.gains_message || gainsReport.detail || gainsReport.content)) {
      return <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="warning">{JSON.stringify(gainsReport)}</Alert>
      </Stack>;
    }
    return "";

  }

  if (user_info.email && user_info.session_id) {
    return (
      <>
        <Header />
        <div style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#e5e5e5',
          paddingTop: '100px'
        }}>
          <Grid container justifyContent="center">
            <Box sx={{
              margin: '20px', background: '#fff', width: '100%',
              padding: '25px',
              borderRadius: '5px'
            }}>
              <div className="main_top">
                <p className="ex_p">Estimated Taxable Gains / Losses</p>
              </div>
              <div className="grey_line"></div>
              {selectedCustomer && user_info.user_type === "Accountant" && <div className="selected-client"> <span>  <ManageAccountsSharpIcon /> Selected Client</span> <span>{selectedCustomer}</span></div>}
              <div style={{ marginBottom: '40px' }}></div>

              <Grid
                container
                spacing={2} style={{ marginBottom: 10 }}>
                <Grid className="date-picker" item xs={3}>
                  <FormControl fullWidth>
                    <DatePicker
                      selected={startDate}
                      dateFormat="yyyy/MM/dd"
                      onChange={(date) => setDate(date, 'START_DATE')}
                      selectsStart
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date*"
                    />
                  </FormControl>
                </Grid>
                <Grid className="date-picker" item xs={3}>
                  <FormControl fullWidth>
                    <DatePicker
                      selected={endDate}
                      dateFormat="yyyy/MM/dd"
                      onChange={(date) => setDate(date, 'END_DATE')}
                      selectsEnd
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date*"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="mode-label">Mode</InputLabel>
                    <Select
                      labelId="mode-label"
                      id="mode-select"
                      value={mode}
                      label="Mode"
                      onChange={handleChange}
                    >
                      <MenuItem value={"HIFO"}>HIFO</MenuItem>
                      <MenuItem value={"FIFO"}>FIFO</MenuItem>
                      <MenuItem value={"LIFO"}>LIFO</MenuItem>
                    </Select>
                  </FormControl></Grid>
                <Grid item xs={3}>
                  {isValidDate && <button className="go" onClick={showGains}>Go</button>}
                  {isValidDate && isSuccess && gainsReport.nature_of_income && <button className="go" onClick={sendEmailGainsReport}>Email Reports</button>}
                </Grid>
              </Grid>
              {getSendReportStatus()}
              {getDisplayRecords()}
            </Box>
          </Grid>
        </div>

      </>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default CryptoGains;
