import React, { useEffect, useState } from 'react';
import "./ChangePassword.css";
import arrow from "../../assets/img/Arrow.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/Logo.png";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { changePassword } from "../../api/userAPI";
import passwordSchema from "../../schema/passwordSchema";
import { Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ChangePassword = () => {

  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
  };
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [data, setData] = useState(null);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [changePasswordFaliure, setChangePasswordFailure] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);

  const reset = () => {
    setChangePasswordSuccess(false);
    setChangePasswordFailure(false);
    setNewPassword('');
    setOldPassword('');
    setData(null);
    setNewConfirmPassword('');
    setShow(false);
    setShowTwo(false);
    setIsLogin(false);
  }

  useEffect(() => { reset() }, []);

  const toggleShow = (e) => {
    e.preventDefault();
    setShow((prevState) => !prevState);
  };

  const toggleShowTwo = (e) => {
    e.preventDefault();
    setShowTwo((prevState) => !prevState);
  };

  const redirectToLogin = () => {
    navigate("/");
  }


  const onChange = (e) => {
    const { value, name } = e.target;
    setChangePasswordSuccess(false);
    setChangePasswordFailure(false);
    setData(null);
    if (name === "oldPassword") {
   
      setOldPassword(value);
    }
    if (name === "newPassword") {
      setNewPassword(value);
    }
    if (name === "newConfirmPassword") {
      setNewConfirmPassword(value);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    // const isValid = passwordSchema.validate(newPassword);
    // if(!isValid){
    //   setChangePasswordFailure(true);
    //   setData({ content: { msg: "New Password Should Follow Password Policy" } });
    //   return;
    // }

    if(newPassword !== newConfirmPassword){
      setChangePasswordFailure(true);
      setData({ content: { msg: "New Password and Confirm New Password Values are not Same" } });
      return;
    }
    console.log(oldPassword,newPassword,newConfirmPassword);

    try {
      const res = await changePassword({
        session_id: user_info.session_id,
        email: user_info.email,
        oldPassword,
        newPassword
      });

      if (res.ok) {
        setChangePasswordSuccess(true);
        setData(await res.json());
        setIsLogin(true);
        window.sessionStorage.clear();
      } else {
        setChangePasswordFailure(true);
        setData(await res.json());
      }

    } catch (err) {
      setChangePasswordFailure(true);
      if (err.message === "Failed to fetch") {
        setData({ content: { msg: "Something Went Wrong. Please Try Again After Some Time" } });
      } else {
        setChangePasswordFailure(true);
        setData({ content: { msg: err.message } });
      }
    }
  };

  if (user_info.email && user_info.session_id) {
    return (
      <div className="wrapper_resetpassword">
        <div className="banner_reset">
          <div className="arrow_div">
            {" "}
            <Link className="back" to="/">
              {" "}
              <img src={arrow} alt="" /> Back
            </Link>
          </div>

          <div className="logo_div">
            <figure className="logo">
              <img src={logo} alt="logo" />
            </figure>
          </div>
          <div className="line">
          </div>
          <form className="form_newpass" onSubmit={onSubmit}>
            
            <input
              type={show ? "text" : "password"}
              placeholder="Old Password"
              className="newpass"
              id="oldPassword"
              name="oldPassword"
              value={oldPassword}
              onChange={onChange}
              required
            />

            <button className="eyer" onClick={toggleShow}>
              <RemoveRedEyeIcon style={{ color: `${show ? '#ea1c1c' : 'grey'}` }} />
            </button>
            <input
              type={showTwo ? "text" : "password"}
              placeholder="New Password"
              className="newpass"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={onChange}
              required
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" 
            />
            <button className="eyerr" onClick={toggleShowTwo}>
              <RemoveRedEyeIcon style={{ color: `${showTwo ? '#ea1c1c ' : 'grey'}` }} />
            </button>

            <input
              type="password"
              placeholder="Confirm New Password"
              className="newpass"
              id="newConfirmPassword"
              name="newConfirmPassword"
              value={newConfirmPassword}
              onChange={onChange}
              required
            />
            {(changePasswordSuccess || changePasswordFaliure) && <Stack sx={{ width: '100%', marginTop: '10px' }} spacing={2}>
              <Alert severity={changePasswordSuccess ? "success" : changePasswordFaliure ? "error" : "info"}>{data ? data.content ? data.content.msg : data.detail ? "Invalid Request" : JSON.stringify(data) : ""}</Alert>
            </Stack>}
            <button className="submit_btn" type="submit">
              Change Password
            </button>

            {isLogin && <button onClick={redirectToLogin} className="submit_btn" type="button">
              Login
            </button>}

          </form>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default ChangePassword;
