import React, { useState, useEffect } from "react";
import "./PlanPurchase.css";
import logo from "../../assets/img/Logo.png";
import fb from "../../assets/img/fb.png";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import arrow from "../../assets/img/Arrow.png";
import eye from "../../assets/img/eye.png";
import { initPurchasePlanState } from "../../constants/initStates";
import { getCountries } from "../../api/others";
import { purchasePlan } from "../../api/userAPI";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../../features/CountrySlice";

const PlanPurchase = () => {
  const [show, setShow] = useState(false);
  const [showFirst, setShowFirst] = useState(false);
  const [signupState, setSignupState] = useState(initPurchasePlanState);
  

  const dispatch = useDispatch()
  const country  = useSelector(getCountry)
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);


  const onSignupChange = (e) => {
    const { id, value } = e.target;
    setSignupState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    // console.log(signupState);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    signupState['plan'] = params.get("plan");
    // console.log(signupState);
    if (document.getElementById("password").value !== document.getElementById("password2").value) {
      alert("Passwords don't match");
    } else if (!document.getElementById("country").value) {
      alert("Country cannot be empty");
    } else {
      dispatch(purchasePlan(signupState))
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult)
        if (originalPromiseResult.meta.requestStatus == "fulfilled") {
	    window.location.href = originalPromiseResult.payload.url
         // navigate(originalPromiseResult.payload.url, {state : originalPromiseResult.payload.content})        
        } else if (originalPromiseResult.meta.requestStatus == "rejected") {
          alert(originalPromiseResult.payload.content)        
        }
   
      })
      .catch((rejectedValueOrSerializedError) => {
        alert("Plan purchase failed. Please reach out to Defitaxcalc.io support.")
      })
    }
  };

  const toggleShow = (e) => {
    e.preventDefault();
    setShow((prevState) => !prevState);
  };

  const toggleShowFirst = (e) => {
    e.preventDefault();
    setShowFirst((prevState) => !prevState);
  };

  const responseFacebook = (response) => {
    // console.log("res", response);
  };

  const componentClicked = (data) => {
    // console.warn(data);
  };

  const onFailureSuccess = (res) => {
    // console.log("Failure Success", res);
  };

  const onLoginSuccess = (res) => {
    // console.log("Login Success", res);
  };

  return (
    <div className="wrapper_signup">
      <div className="signup_div">
        <div className="arrow_div">
          <Link className="back" to="https://defitaxcalc.io/">
            <img src={arrow} alt="arrow" /> Back
          </Link>
        </div>
        <div className="logo_div">
          <figure className="logo">
            <img src={logo} alt="logo" />
          </figure>
        </div>
        <div className="line">
        </div>
        <div className="line">
        </div>        
        <form onSubmit={onSubmit} className="form_signup">
          <div className="form_signup_div1">
            <input
              type="text"
              placeholder="First Name"
              id="firstname"
              required
              onChange={onSignupChange}
            />
            <input
              type="text"
              required
              placeholder="Last Name"
              id="lastname"
              onChange={onSignupChange}
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            id="email"
            required
            onChange={onSignupChange}
          />
          <select name="" id="country" onChange={onSignupChange}>
            <option key="" value="">
                  Country
                </option>
             {country.map((item) => 
              // return (
                <option key={item} value={item}>
                  {item}
                </option>
              // );
            )} 
          </select>
          <div className="form_signup_div2">
            <input
              onChange={onSignupChange}
              type={showFirst ? "text" : "password"}
              placeholder="Password"
              id="password"
              className="passwordd"
              required
            />
            <input
              type={show ? "text" : "password"}
              placeholder="Confirm Password"
              required
              onChange={onSignupChange}
              id="password2"
            />
          </div>          
          <button type="submit" className="submit_btn">
            Purchase Plan
          </button>
        </form>
      </div>
    </div>
  );
};

export default PlanPurchase;
