import { createSlice } from "@reduxjs/toolkit";
import { confirmEmail, loginUser, logoutUser, postUser, resetPassword } from "../api/userAPI";
import { initSignupState } from "../constants/initStates";

const initialState = {
  signup: initSignupState,
};

const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
        
  },

  extraReducers: {

    [postUser.pending]: (state) => {
      console.log("pending");
    },

    [postUser.fulfilled]: (state, { payload }) => {
      console.log("Success");
    },
   
    [postUser.rejected]: (state) => {
      console.log("rejected");
    },


    [loginUser.pending] : (state) => {
        console.log("pending");
    },
    [loginUser.fulfilled]: (state,{ payload }) => {
        console.log("fulfilled");
    },
    [loginUser.rejected] : (state) => {
        console.log("rejected");
    },


    [logoutUser.pending] : (state) => {
        console.log("pending");
    },
    [logoutUser.fulfilled]: (state,{ payload }) => {
        console.log("fulfilled");
    },
    [logoutUser.rejected] : (state) => {
        console.log("rejected");
    },



    [confirmEmail.pending] : (state) => {
        console.log("pending");
    },
    [confirmEmail.fulfilled]: (state,{ payload }) => {
        console.log("fulfilled");
    },
    [confirmEmail.rejected] : (state) => {
        console.log("rejected");
    },


    [resetPassword.pending] : (state) => {
        console.log("pending");
    },
    [resetPassword.fulfilled]: (state,{ payload }) => {
        console.log("fulfilled");
    },
    [resetPassword.rejected] : (state) => {
        console.log("rejected");
    },


    
  },
});



export const getSignup = (state) => state.signup.signup;
export default userSlice.reducer;
