import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import close from "../../assets/img/close.png";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useDropzone } from 'react-dropzone';
import Select from '@mui/material/Select';
import { uploadReportSummary } from "../../api/exchangeAPI";
import { getSelectedCustomer } from "../../features/CustomerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getReportUploadStatus, resetReportUpload, getSupportedExchangesList } from "../../features/ExchangeSlice";

import { useState } from "react";
import "./AddReport.css";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "2px solid #000",
    borderRadius: "16px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15);",
    pt: 2,
    px: 4,
    pb: 3,
};



export default function AddReport() {

    const dispatch = useDispatch()
    const [uploadStatus, isSuccess, isError, isLoading] = useSelector(getReportUploadStatus);
    const selectedCustomer = useSelector(getSelectedCustomer);
    const supportedExchanges = useSelector(getSupportedExchangesList);
    const [myFiles, setMyFiles] = useState([]);
    const [accept, setAccept] = useState({
        'text/csv': [".csv"],
    });

    const onDrop = React.useCallback(acceptedFiles => {
        dispatch(resetReportUpload());
        setMyFiles([...acceptedFiles]);
    }, [myFiles])


    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept:accept
    });


    const files = myFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes{" "}
        </li>
    ))

    const [open, setOpen] = React.useState(false);
    const [exchange, setExchange] = React.useState('Binance');
    const handleChange = (event) => {
        if(event.target.value === 'Others'){
            setAccept({
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [".xlsx" ],
                'application/vnd.ms-excel': [".xls" ],
               
            })
        }else{
            setAccept({
                'text/csv': [".csv"],
            })
        }
        setMyFiles([]);
        dispatch(resetReportUpload());
        setExchange(event.target.value);
    };

    const uploadFile = () => {
        let user_info = {
            "email": window.sessionStorage.getItem("email"),
            exchange,
            file: myFiles[0],
            "user_type": window.sessionStorage.getItem("user_type"),
            "cust_id": selectedCustomer,
            "session_id": window.sessionStorage.getItem("session_id")
        };
        dispatch(uploadReportSummary(user_info));
    }
    const handleOpen = () => {
        setOpen(true);
        dispatch(resetReportUpload());
        setMyFiles([]);
        setExchange('Binance');
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <Button
                sx={{
                    background:
                        "linear-gradient(219.14deg, #f9c050 3.52%, #ea1c1c 149.82%)",
                    borderRadius: "4px",
                    padding: "8px 50px",
                    border: "none",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "white",
                }}
                onClick={handleOpen}
            >
                Add Record
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 848, height: 544 }}>
                    <div className="modal_flex">
                        {" "}
                        <h2 id="parent-modal-title">Add New Record</h2>
                        <button className="btn_close_modal" onClick={handleClose}>
                            <img src={close} alt="" />
                        </button>
                    </div>

                    <div className="grey_line_modal"></div>
                    <form className="form_modal">
                        <FormControl fullWidth>
                            <InputLabel id="exchange-label">Exchanges</InputLabel>
                            <Select
                                labelId="exchange-label"
                                id="exchange-select"
                                value={exchange}
                                label="Exchange"
                                onChange={handleChange}
                            >


                                <MenuItem value="">Select</MenuItem>
                                {supportedExchanges.length > 0 && supportedExchanges.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}


                            </Select>
                        </FormControl>
                        {isLoading ? <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Box sx={{ display: 'flex', marginTop: 5, minHeight: 85 }}>
                                <CircularProgress />
                            </Box>
                        </Grid>
                            : <FormControl fullWidth>
                                <div style={{ marginTop: 25, minHeight: 100 }} {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some { exchange=== "Others" ? "xlsx, xls" :"csv" } file here, or click to select { exchange=== "Others" ? "xlsx, xls" :"csv" } file</p>
                                    <em>(Note: maximum number of files you can drop here is 1)</em>
                                </div>
                            </FormControl>

                        }
                        <Grid container direction="column" style={{ marginTop: 25 }}>
                            <h4>file selected</h4>
                            <ul>{files}</ul>
                            {isSuccess && <FormControl fullWidth><Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="success">{uploadStatus.content ? uploadStatus.content.msg : JSON.stringify(uploadStatus)}</Alert>
                            </Stack></FormControl>}
                            {isError && <FormControl fullWidth><Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="error">{uploadStatus.content ? uploadStatus.content.msg : uploadStatus.detail ? "Invalid Request" : JSON.stringify(uploadStatus)}</Alert>
                            </Stack></FormControl>}
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            sx={{ marginTop: '15px' }}>
                            <button type="button" onClick={handleClose} className="std_button" style={{ background: "#fff", color: "#000", border: "1px solid #000" }}>
                                Cancel
                            </button>

                            {myFiles.length > 0 && <button
                                type="button"
                                className="std_button"
                                onClick={uploadFile}
                            >
                                Upload
                            </button>}

                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
