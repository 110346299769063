import React from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Fade from "@mui/material/Fade";
import { getCustomersList } from "../../api/customerAPI";
import Header from "../../components/Header/Header";
import CustomerList from "../../components/CustomerList/CustomerList";
import { getAccountantCustomersList, selectCustomer, getSelectedCustomer } from "../../features/CustomerSlice";
import AddCustomer from "../../components/AddCustomer/AddCustomer";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import "./Customer.css";

const Customer = () => {
  const dispatch = useDispatch();
  const navigate_to = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [open, setOpen] = React.useState(params.get("add_client") && params.get("add_client") == "fail");
  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
    "user_type": window.sessionStorage.getItem("user_type")
  };
  const [customers, isSuccess, isError, isLoading] = useSelector(getAccountantCustomersList);
  const selectedCustomer = useSelector(getSelectedCustomer);

  const handleChange = (event) => {
    dispatch(selectCustomer(event.target.value))

  }

  React.useEffect(() => {
    if (user_info.email && user_info.session_id && user_info.user_type === "Accountant") {
      dispatch(getCustomersList(user_info));
    }
    return () => {
    }
  }, []);

  if (user_info.email && user_info.session_id && user_info.user_type === "Accountant") {

    return (
      <>

        <Header />
        <div style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#e5e5e5',
          paddingTop: '100px'
        }}>
          <Grid container justifyContent="center">
            <Box sx={{
              margin: '20px', background: '#fff', width: '100%',
              padding: '25px',
              borderRadius: '5px'
            }}>
              <div className="main_top">

                <p className="estimated">Clients</p>
                <div className="end">
                  <Button
                      sx={{
                          background:
                              "linear-gradient(219.14deg, #f9c050 3.52%, #ea1c1c 149.82%)",
                          borderRadius: "4px",
                          padding: "8px 50px",
                          border: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          color: "white",
                      }}
                      onClick={()=>navigate_to("/customerAdd")}
                  >
                      Add Client
                  </Button>
                </div>
              </div>
              <div className="grey_line"></div>
              <FormControl sx={{ marginTop: '30px', minWidth: 500 }}>

                <InputLabel id="customer-selected-label">Select Client*</InputLabel>
                <Select
                  labelId="customer-selected"
                  id="customer-selected"
                  name="customer-selected"
                  value={selectedCustomer}
                  label="Select Customer"
                  onChange={handleChange}

                >
                  {isSuccess && customers.length > 0 ? customers.map(item => <MenuItem key={item.email} value={item.email}>{item.email}</MenuItem>) : null}

                </Select>
                {!selectedCustomer && <FormHelperText sx={{ color: 'red' }}>Please Select Any One Client To Proceed Further</FormHelperText>}
              </FormControl>
              <CustomerList onDelete={handleChange} />
            </Box>
          </Grid>
            <Fade
               in={open}
               timeout={{ enter: 1000, exit: 1000 }}
               addEndListener={() => {
                 setTimeout(() => {
                   setOpen(false)
                 }, 5000);
               }}
               >          
                <Alert variant="filled" severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }                
                >
                Add client failed due to payment issues, please reach out to <a href="mailto:support@defitaxcalc.io">DefitaxCalc support.</a>
                </Alert>
            </Fade>
        </div>
      </>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default Customer;
