import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './ExchangeList.css';
import ExchangesListItem from './ExchangesListItem';
import { getReportSummary,deleteReport } from "../../api/exchangeAPI";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCustomer } from "../../features/CustomerSlice";

import { getExchangeReportSummary, resetReportSummary } from "../../features/ExchangeSlice";
import ExchangesListLoader from "./ExchangesListLoader";
import { Table } from './style';

const ExchangesList = () => {

  const dispatch = useDispatch();
  const [summary, isSuccess, isError, isLoading] = useSelector(getExchangeReportSummary);
  const selectedCustomer = useSelector(getSelectedCustomer);

  useEffect(() => {

    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      "session_id": window.sessionStorage.getItem("session_id"),
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer
    };

    dispatch(getReportSummary(user_info));

    return () => {
      dispatch(resetReportSummary());
    }


  }, [])

  if (isLoading) return <ExchangesListLoader />;
  if (isError) return <Stack sx={{ width: '100%' }} spacing={2}>
    <Alert severity="error">{summary.content ? summary.content.msg : summary.detail ? "Invalid Request" : JSON.stringify(summary)}</Alert>
  </Stack>;


  if (isSuccess && summary.length == 0) return <Stack sx={{ width: '100%' }} spacing={2}>
    <Alert severity="info">No Records Found</Alert>
  </Stack>;

  return (
    <Table>
      <thead>
        <tr>
     
          <th>Exchange</th>
          <th>File Uploaded</th>
          <th>Date Uploaded</th>
          <th>Number of Txns </th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Valid</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {isSuccess && summary.length > 0 && summary.map((item, index) => <ExchangesListItem data={item} key={index} />)}
      </tbody>
    </Table>
  );
};

export default ExchangesList;
