export const BASE_URL = 'https://api.defitaxcalc.io'
export const REPORTS_SUMMARY_URL = '/reports/summary/';
export const REPORTS_VALIDATE_URL = '/reports/validate/';
export const TRANSACTIONS_URL = '/transactions/';
export const REPORTS_UPLOAD_URL = '/reports/uploadfile/';
export const REPORTS_URL = '/reports/';

export const GAINS_URL = '/gains/';
export const CUSTOMERS_URL = '/customers/';

export const SUPPORTED_COINS_URL = '/dtc/supported_coins/';
export const SUPPORTED_TRANSACTIONS_URL = '/dtc/supported_transactions/';
export const SUPPORTED_EXCHANGES_URL = '/dtc/supported_exchanges/';

export const EMAIL_TAX_GAINS_REPORT_URL= '/emailyearendtaxgainsreport/';
export const USERS= '/users/';
export const CHANGE_PASSWORD = '/change_password';

export const PURCHASE_PLAN = '/purchase_plan/';

