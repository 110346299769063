import { configureStore } from "@reduxjs/toolkit";
import countryReducer from "../features/CountrySlice";
import userReducer from "../features/UserSlice";
import exchangeReducer from "../features/ExchangeSlice";
import transactionReducer from "../features/TransactionSlice";
import cryptoGainsReducer from "../features/CryptoGainsSlice";
import customerSliceReducer from "../features/CustomerSlice";


export const store = configureStore({
  reducer: {
    countries: countryReducer,
    signup: userReducer,
    exchange: exchangeReducer,
    transactions: transactionReducer,
    cryptoGains: cryptoGainsReducer,
    customer: customerSliceReducer

  },
});
