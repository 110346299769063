import React, { useEffect } from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";

import reverse from "../../assets/img/back.png";
import { Navigate } from "react-router-dom";
import { paginationLocaleInfo } from '../../locale/en_US'
import 'rc-pagination/assets/index.css';
import Pagination from 'rc-pagination';
import Select from 'rc-select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import "./Transactions.css";
import TransactionsList from "../../components/TransactionsList/TransactionsList";
import TransactionsListLoader from "../../components/TransactionsList/TransactionsListLoader";
import ManageTransaction from '../../components/ManageTransaction/ManageTransaction';
import { useState } from "react";
import { getSelectedCustomer } from "../../features/CustomerSlice";
import { getTransaction, addTransaction } from "../../api/transactionAPI";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsList, resetTransactionsList } from "../../features/TransactionSlice";
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import { transactionSchema } from "../../schema/transactionSchema";



const Transactions = () => {

  const dispatch = useDispatch();

  const [data = { txns: { _metadata: [], dataset: [] } }, isSuccess, isError, isLoading] = useSelector(getTransactionsList);

  const selectedCustomer = useSelector(getSelectedCustomer);

  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
    "user_type": window.sessionStorage.getItem("user_type"),
    "cust_id": selectedCustomer,
  };

  useEffect(() => {
    if (user_info.email && user_info.session_id) {
      dispatch(getTransaction(user_info));
    }
    return () => {
      dispatch(resetTransactionsList())
    }
  }, [])


  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [isProcessed, setIsProcessed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (!isLoading && isSuccess) {

      if (data && data.txns && data.txns._metadata) {
        setIsProcessed(false);
        const { txns: { _metadata: headers = [], dataset = [] } } = JSON.parse(JSON.stringify(data));
        setHeaders([...headers]);
        setDataset([...dataset]);
        setRows(() => {
          setIsProcessed(true);
          return dataset.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        });

      }
    }
  }, [isLoading, isSuccess, data])


  const onChange = (current, p) => {
    const c = current === 0 ? 1 : current;
    setCurrentPage(c);
    setPageSize(p);
    setRows(() => dataset.slice((c - 1) * p, c * p));
  };

 
  const createTxn = (data) => {

    const { isEdited, ...payload } = { ...data };


    const requestBody = {is_valid:1};

    Object.keys(transactionSchema).forEach(key => {
      if (payload.hasOwnProperty(key)) {
        if (transactionSchema[key] === "number") {
          requestBody[key] = Number(payload[key]);
        } else {
          requestBody[key] = payload[key];
        }
      }
    });

    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      "body": { "insert": [{ ...requestBody }] },
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": selectedCustomer,
      "session_id": window.sessionStorage.getItem("session_id")
    };
    dispatch(addTransaction(user_info));
    setCurrentPage(1);
  }

  const getDisplayRecords = () => {
    if (isLoading) { return <TransactionsListLoader /> }
    if (isError) {
      return <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{data.content ? data.content.msg : data.detail ? "Invalid Request" : JSON.stringify(data)}</Alert>
      </Stack>
    }
    if (isSuccess && isProcessed) {
      if (data && data.txns && data.txns.dataset) {
        if (data.txns.dataset.length > 0) {
          return <TransactionsList lastModified={data.last_modified} headers={headers} rows={rows} />
        }
        else {
          return <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="info">No Records Found</Alert>
          </Stack>;
        }
      }
    }
    return "";
  }
  if (user_info.email && user_info.session_id) {
    return (
      <>
        <Header />
        <div style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#e5e5e5',
          paddingTop: '100px'
        }}>
          <Grid container justifyContent="center">
            <Box sx={{
              margin: '20px', background: '#fff', width: '100%',
              padding: '25px',
              borderRadius: '5px'
            }}>
              <div className="main_top">
                <p className="trans">Transactions</p>
                <div className="end">
                  <ManageTransaction data={{}} headers={headers} setData={createTxn} mode={'Create'} />
                </div>
              </div>
              <div className="grey_line"></div>
              {selectedCustomer && user_info.user_type === "Accountant" && <div className="selected-client"> <span>  <ManageAccountsSharpIcon /> Selected Client</span> <span>{selectedCustomer}</span></div>}
              {!isLoading && isSuccess && !isError &&  <h3>Total Transactions : {dataset.length}</h3>}
              <div className="input_div" style={{ marginBottom: 10 }}>
                {!isLoading && isSuccess && !isError && <Pagination
                  selectComponentClass={Select}
                  showSizeChanger
                  showQuickJumper={{ goButton: <button type="button">Go</button> }}
                  defaultPageSize={pageSize}
                  defaultCurrent={currentPage}
                  onChange={onChange}
                  pageSizeOptions={[5, 10, 15, 20]}
                  total={dataset.length}
                  locale={paginationLocaleInfo}
                
                />}
              </div>
              {getDisplayRecords()}
            </Box>
          </Grid>

        </div>
      </>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default Transactions;
