import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import close from "../../assets/img/close.png";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { addCustomer, getCustomersList } from "../../api/customerAPI";
import Select from '@mui/material/Select';
import { getCountry } from "../../features/CountrySlice";
import { addCustomerStatus, resetAddCustomer } from "../../features/CustomerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../api/others";

import "./AddCustomer.css";

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import {
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import '@fortawesome/fontawesome-free/css/all.min.css';


const CustomerAdd = () => {
  const dispatch = useDispatch();
  React.useEffect(() => { dispatch(getCountries()) }, [])
  const navigate_to = useNavigate();
  
  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
    "user_type": window.sessionStorage.getItem("user_type")
  };
  
  const countryList = useSelector(getCountry)
  const [statusMessage, isSuccess, isError, isLoading] = useSelector(addCustomerStatus);


  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [country, setCountry] = React.useState("");


  const handleChange = (event) => {

      const { name, value } = event.target
      switch (name) {
          case "customer-country":
              setCountry(value);
              break;
          case "customer-email":
              setEmail(value);
              break;
          case "customer-lastname":
              setLastName(value);
              break;
          case "customer-firstname":
              setFirstName(value);
              break;
          default:
              break;
      }

  };
  
    const handleClose = () => {
        navigate_to("/clients");
    };
  
    const create = (e) => {

        let user_info = {
            "email": window.sessionStorage.getItem("email"),
            customer: {
                "firstname": firstName,
                "lastname": lastName,
                "email": email,
                "country": country,
                "plan": e.target.value
            },
            "session_id": window.sessionStorage.getItem("session_id")
        };

        console.log(user_info);
        dispatch(addCustomer(user_info));

    };


  if (user_info.email && user_info.session_id && user_info.user_type === "Accountant") {

    return (
      <>

        <div style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#e5e5e5',
          paddingTop: '100px'
        }}>
          <Grid container justifyContent="center">
                <Box sx={{
                  margin: '20px', background: '#fff', width: '75%',
                  padding: '25px',
                  borderRadius: '5px'
                }}>
                    <div className="modal_flex">
                        {" "}
                        <h2 id="parent-modal-title">Add Client</h2>
                    </div>

                    <div className="grey_line_modal"></div>
                    <form className="form_modal" style={{ height: 'calc(100% - 120px)', padding: '20px 10px 10px 10px', overflow: 'auto' }}>
                        <Grid container >

                            <FormControl sx={{ marginBottom: '30px', marginRight: '5px', width: '49%' }} >
                                <TextField
                                    name="customer-firstname"
                                    id="customer-firstname"
                                    label="First Name"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={handleChange}

                                />
                            </FormControl>

                            <FormControl  sx={{ marginBottom: '30px', marginRight: '5px', width: '49%' }}>
                                <TextField
                                    name="customer-lastname"
                                    id="customer-lastname"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={handleChange}

                                />
                            </FormControl>
                            <FormControl sx={{ marginBottom: '30px', marginRight: '5px', width: '49%' }}>
                                <TextField
                                    name="customer-email"
                                    id="customer-email"
                                    label="Email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleChange}

                                />
                            </FormControl>
                            <FormControl sx={{ marginBottom: '30px', marginRight: '5px', width: '49%' }}>
                                <InputLabel id="customer-country-label">Country</InputLabel>
                                <Select
                                    labelId="customer-country"
                                    id="customer-country"
                                    name="customer-country"
                                    value={country}
                                    label="Country"
                                    onChange={handleChange}

                                >
                                    {countryList?.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}

                                </Select>
                            </FormControl>

                        </Grid>


                      <MDBContainer className="py-5 text-center">
                        <MDBTable
                          responsive
                          striped
                          className="text-successtable-border border-light"
                        >
                          <MDBTableHead className="border-light">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col" text-align="center" style={{width: '20%'}}>
                                <strong>Standard</strong>
                              </th>
                              <th scope="col" text-align="center" style={{width: '30%'}}>
                                <strong>Complete Tax Reports</strong>
                              </th>
                              <th scope="col" text-align="center" style={{width: '30%'}}>
                                <strong>White Labelled Tax Reports</strong>
                              </th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            <tr>
                              <th scope="row" text-align="right">Transactions</th>
                              <td>10, 000</td>
                              <td>10, 000</td>
                              <td>10, 000</td>
                            </tr>
                            <tr>
                              <th scope="row" text-align="right">FIFO, LIFO, HIFO</th>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" text-align="right">Reconciliation and reports preparation by DeFi Tax Calc</th>
                              <td>-</td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" text-align="right">White labelled year-end report</th>
                              <td>-</td>
                              <td>-</td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" text-align="right">Platforms</th>
                              <td>Unlimited</td>
                              <td>Unlimited</td>
                              <td>Unlimited</td>
                            </tr>
                            <tr>
                              <th scope="row" text-align="right">On-call support for crypto queries</th>
                              <td>-</td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                              <td>
                                <MDBIcon fas icon="check" className="text-success" />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">&gt; 5+ clients</th>
                              <td className="fw-bold" colspan="3">
                                <a href="#">Ask us for customised quotes</a>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="fw-bold">A$88</td>
                              <td className="fw-bold">A$165</td>
                              <td className="fw-bold">A$330</td>
                            </tr>                            
                            <tr>
                              <th></th>
                              <th>
                                {firstName && lastName && email && country && (<MDBBtn
                                    type="button"
                                    className="std_button"
                                    onClick={create}
                                    id="customer-Plan1"
                                    value="Standard"
                                >
                                    Buy Plan
                                </MDBBtn>)}                                
                              </th>
                              <th>
                                {firstName && lastName && email && country && (<MDBBtn
                                    type="button"
                                    className="std_button"
                                    onClick={create}
                                    id="customer-Plan2"
                                    value="Advance"
                                >
                                    Buy Plan
                                </MDBBtn>)}                                

                              </th>
                              <th>
                                {firstName && lastName && email && country && (<MDBBtn
                                    type="button"
                                    className="std_button"
                                    onClick={create}
                                    id="customer-Plan3"
                                    value="Advance Plus"
                                >
                                    Buy Plan
                                </MDBBtn>)}                                

                              </th>
                            </tr>
                          </MDBTableBody>
                        </MDBTable>
                      </MDBContainer>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end">
                            <button      type="button" onClick={handleClose} className="std_button" style={{ background: "#fff", color: "#000", border: "1px solid #000" }}>
                                Cancel
                            </button>
                        </Grid>
                        <Grid container direction="column" style={{ marginTop: 0, marginBottom: 5, minHeight: 80 }}>
                            {isLoading && <CircularProgress />}
                            {isSuccess && <FormControl fullWidth><Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="success">{statusMessage.content ? statusMessage.content.msg : JSON.stringify(statusMessage)}</Alert>
                            </Stack></FormControl>}
                            {isError && <FormControl fullWidth><Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="error">{statusMessage.content ? statusMessage.content.msg : statusMessage.detail ? "Invalid Request" : JSON.stringify(statusMessage)}</Alert>
                            </Stack></FormControl>}
                        </Grid>
                        
                    </form>
                </Box>        
          </Grid>
        </div>
      </>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default CustomerAdd;
