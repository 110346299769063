import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import './CustomerList.css';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import { getCustomersList, deleteCustomer } from "../../api/customerAPI";
import { getAccountantCustomersList, resetCustomersList, getSelectedCustomer } from "../../features/CustomerSlice";
import CustomerListLoader from "./CustomerListLoader";
import { Table } from './style';

const CustomerList = ({ onDelete }) => {

  const dispatch = useDispatch();
  const selectedCustomer = useSelector(getSelectedCustomer);
  // useEffect(() => {
  //   let user_info = { "email": window.sessionStorage.getItem("email"), "session_id": window.sessionStorage.getItem("session_id") };
  //   dispatch(getCustomersList(user_info));
  //   return () => {
  //   }
  // }, []);


  const deleteSelectedCustomer = (customer) => {
    let user_info = {
      customer,
      "email": window.sessionStorage.getItem("email"),
      "session_id": window.sessionStorage.getItem("session_id")
    };
    dispatch(deleteCustomer(user_info));
    if (customer.email === selectedCustomer) {
      onDelete({ target: { value: "" } })
    }

  }

  const [customers = [], isSuccess = false, isError = false, isLoading = false] = useSelector(getAccountantCustomersList);


  if (isLoading) return <CustomerListLoader />;
  if (isError) return <Stack sx={{ width: '100%' }} spacing={2}>
    <Alert severity="error">{customers.content ? customers.content.msg : customers.detail ? "Invalid Request" : JSON.stringify(customers)}</Alert>
  </Stack>;

  if (isSuccess && customers.length == 0) return <Stack sx={{ width: '100%' }} spacing={2}>
    <Alert severity="info">No Records Found</Alert>
  </Stack>;

  return (
    <Table>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Country</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {isSuccess && customers.length > 0 && customers.map((item, index) => <tr key={index} >
          <td>{item.firstname}</td>
          <td>{item.lastname}</td>
          <td>{item.country}</td>
          <td>{item.email}</td>
          <td>
            <IconButton className="action-button" onClick={deleteSelectedCustomer.bind(this, item)} aria-label="delete">
              <DeleteOutlinedIcon style={{ color: '#ccc' }} />
            </IconButton>
          </td>
        </tr>)}
      </tbody>
    </Table>
  );
};

export default CustomerList;
