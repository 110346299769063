import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../api/others";

const initialState = {
  countries: [],
  success: "",
  error: "",
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  
  extraReducers: {
    [getCountries.fulfilled]: (state, {payload} ) => {
      state.countries = payload ;
    },
    [getCountries.pending]: (state) => {
      state.success = "";
      state.error = "";
    },
    [getCountries.rejected]: (state) => {
      state.error = "Failed";
      console.log("failed");
    },
  },
});

export const getCountry = (state) => state.countries.countries;

export default countrySlice.reducer;
