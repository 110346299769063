import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, CUSTOMERS_URL } from "./APIConstants";




export const getCustomersList = createAsyncThunk('customers/list', async ({ email, session_id }, { rejectWithValue, fulfillWithValue }) => {


  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const res = await fetch(`${BASE_URL}${CUSTOMERS_URL}?email=${email}`, requestOptions);
    if (res.ok) {
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});







export const addCustomer = createAsyncThunk('customers/add', async ({ email, session_id, customer }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "firstname": customer.firstname,
    "lastname": customer.lastname,
    "email": customer.email,
    "country": customer.country
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };


  let plan = customer.plan;
  try {
    const res = await fetch(`${BASE_URL}${CUSTOMERS_URL}?email=${email}&plan=${plan}`, requestOptions);
    console.log("addCustomer : " + res);
    if (res.ok) {
      let user_info = { "email": window.sessionStorage.getItem("email"), "session_id": window.sessionStorage.getItem("session_id") };
      dispatch(getCustomersList(user_info));
      var resp = await res.json();
      window.location.href = resp.url;

      // return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});


export const deleteCustomer = createAsyncThunk('customers/delete', async ({ email, session_id, customer }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "firstname": customer.firstname,
    "lastname": customer.lastname,
    "email": customer.email,
    "country": customer.country
  });

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };



  try {
    const res = await fetch(`${BASE_URL}${CUSTOMERS_URL}?email=${email}`, requestOptions);

    if (res.ok) {
      let user_info = { "email": window.sessionStorage.getItem("email"), "session_id": window.sessionStorage.getItem("session_id") };
      dispatch(getCustomersList(user_info));
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});
