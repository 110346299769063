import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, USERS, CHANGE_PASSWORD, PURCHASE_PLAN } from "./APIConstants"

let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

let planUrl = `${BASE_URL}${PURCHASE_PLAN}`;
export const purchasePlan = createAsyncThunk('customers/add', async (plan_details, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", "fad8f769-87a8-4c30-b418-835e406af4e3");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "firstname": plan_details.firstname,
    "lastname": plan_details.lastname,
    "email": plan_details.email,
    "country": plan_details.country,
    "plan": plan_details.plan,
    "password": plan_details.password
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };


  try {
    const res = await fetch(planUrl, requestOptions);

    if (res.ok) {
      var resp = await res.json();
      console.log("**** " + resp)
      //window.location.href = resp.url;
      return fulfillWithValue(resp);
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});

let userUrl = `${BASE_URL}${USERS}`;
export const postUser = createAsyncThunk(
  "user/postUser",
  async (signupState) => {
    const { data } = await axios.post(
      userUrl,
      signupState,
      config
    );
    return data;
  }
);

export const confirmEmail = createAsyncThunk(
  "/confirmemail",
  async (signupState) => {
    const resp = axios({
      method: 'post',
      url: `${BASE_URL}${USERS}${signupState.email}/confirm`,
      params: { 'vid': signupState.vid }
    });
    return resp;
  }
);

export const logoutUser = createAsyncThunk("/logoutUser", async (user_info) => {
  const resp = axios({
    method: 'post',
    url: `${BASE_URL}/logout`,
    headers: { 'x-token-defi': `${user_info.session_id}` },
    params: { 'email': `${user_info.email}` }
  });
  return resp;
});

export const loginUser = createAsyncThunk("/loginUser", async (login) => {
  const { data } = await axios.post(`${BASE_URL}/login`, login);
  return data;
});











export const changePassword = async ({ session_id, email, oldPassword, newPassword }) => {
  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "old_password": oldPassword,
    "new_password": newPassword
  });
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  let url = `${BASE_URL}${USERS}${email}${CHANGE_PASSWORD}`;
  return fetch(url, requestOptions);
}

export const resetPassword = createAsyncThunk(
  "/resetPassword",
  async (login) => {
    const { email } = login.email;
    const { data } = await axios.put(
      `${BASE_URL}${USERS}${email}/reset_password`,
      email
    );
    return data;
  }
);
