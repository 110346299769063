import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, TRANSACTIONS_URL, SUPPORTED_COINS_URL, SUPPORTED_TRANSACTIONS_URL, SUPPORTED_EXCHANGES_URL } from "./APIConstants";


export const updateTransaction = createAsyncThunk('transaction/update', async ({ email, session_id, user_type, cust_id,body }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(body);

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };



  let url = `${BASE_URL}${TRANSACTIONS_URL}?email=${email}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {

      return fulfillWithValue(await res.json());
     
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }

});


export const addTransaction = createAsyncThunk('transaction/add', async ({ email, session_id, user_type, cust_id,body }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(body);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };



  let url = `${BASE_URL}${TRANSACTIONS_URL}?email=${email}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {

      return fulfillWithValue(await res.json());
     
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }

});


export const deleteTransaction = createAsyncThunk('transaction/delete', async ({ email, session_id, user_type, cust_id,body, transaction}, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(body);

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };



  let url = `${BASE_URL}${TRANSACTIONS_URL}?email=${email}&transaction=${transaction}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {

      return fulfillWithValue(await res.json());
     
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }

});




export const getSupportedItems = createAsyncThunk('supported/items', async ({ email, session_id, user_type, cust_id }, { rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };


  let supportedCoinsUrl = `${BASE_URL}${SUPPORTED_COINS_URL}?email=${email}`;
  let supportedTransactionsUrl = `${BASE_URL}${SUPPORTED_TRANSACTIONS_URL}?email=${email}`;
  let supportedExchangesUrl = `${BASE_URL}${SUPPORTED_EXCHANGES_URL}?email=${email}`;
  if (user_type === "Accountant") {
    supportedCoinsUrl = `${supportedCoinsUrl}&cust_id=${cust_id}`;
    supportedTransactionsUrl = `${supportedTransactionsUrl}&cust_id=${cust_id}`;
    supportedExchangesUrl = `${supportedExchangesUrl}&cust_id=${cust_id}`;
  }

  try {
    const responses = await Promise.all([supportedCoinsUrl, supportedTransactionsUrl, supportedExchangesUrl].map(url => fetch(url, requestOptions)));


    if (responses.every(res => res.ok)) {

      const supportedCoins = await responses[0].json();
      const supportedTransactions = await responses[1].json();
      const supportedExchanges = await responses[2].json();

      const cummulativeResponse = {
        supportedCoins,
        supportedTransactions,
        supportedExchanges
      }

      return fulfillWithValue(cummulativeResponse);
    }
    return rejectWithValue({ content: { msg: 'something went wrong with supported items api' } });
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});






export const getTransaction = createAsyncThunk('transaction/getRecords', async ({ email, session_id, user_type, cust_id }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  let url = `${BASE_URL}${TRANSACTIONS_URL}?email=${email}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {
      dispatch(getSupportedItems({ email, session_id, user_type, cust_id }));
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }

});

