import styled from '@emotion/styled'


export const Table = styled.table`
width: 100%;

border-collapse: collapse;
& thead tr:nth-child(1){
  border-top: 1px solid #ccc;
}
& tbody tr { 
  border-bottom: 1px solid #ccc;
}

& tbody tr td{
font-family: "Poppins";
font-style: normal;
font-weight: 400;
font-size: 12px;
color: black;
text-align: left;
padding: 10px;
background-color: #fafafa;
}

tr.txn_component {
  border-top: none;
  & td{
    border-top: 1px solid #ccc;
    background-color: #fff;
  }
}
& tr th{
  color: #848484;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  padding: 10px;
}
`


export const LoaderTable = styled.table`
width: 100%;
margin-top: 50px;
border-collapse: collapse;
& thead tr:nth-child(1){
  border-top: 1px solid #ccc;
}
& tr td{
font-family: "Poppins";
font-style: normal;
font-weight: 400;
font-size: 16px;
color: black;
text-align: center;
padding: 10px;
}

tr.txn_component {
  border-top: 1px solid #ccc;
}
& tr th{
  color: #848484;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding: 10px;
}
`
