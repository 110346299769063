import React from "react";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import "./Exchanges.css";
import { getSelectedCustomer } from "../../features/CustomerSlice";
import { useSelector } from "react-redux";
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import AddReport from "../../components/AddReport/AddReport";
import ExchangesList from "../../components/ExchangesList/ExchangesList";

const Exchanges = () => {

  const selectedCustomer = useSelector(getSelectedCustomer);


  let user_info = { "email": window.sessionStorage.getItem("email"),
   "session_id": window.sessionStorage.getItem("session_id"),
  "user_type" :window.sessionStorage.getItem("user_type")};

  if (user_info.email && user_info.session_id) {
    return (
      <>
        <Header />
        <div style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#e5e5e5',
          paddingTop: '100px'
        }}>
          <Grid container justifyContent="center">
            <Box sx={{
              margin: '20px', background: '#fff', width: '100%',
              padding: '25px',
              borderRadius: '5px'
            }}>
              <div className="main_top">
                <p className="ex_p">Records</p>
                <AddReport />
              </div>
              <div className="grey_line"></div>
              {selectedCustomer && user_info.user_type === "Accountant" && <div className="selected-client"> <span>  <ManageAccountsSharpIcon /> Selected Client</span> <span>{selectedCustomer}</span></div>}
              <ExchangesList />
            </Box>
          </Grid>
        </div>

      </>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default Exchanges;
