import { createSlice } from "@reduxjs/toolkit";
import { getTransaction, getSupportedItems, updateTransaction, addTransaction,deleteTransaction } from "../api/transactionAPI";

const initialState = {
  transactionsList: {},
  getTransactionsLoading: false,
  getTransactionsListSuccess: false,
  getTransactionsListError: false,
  supportedItems: {
    supportedCoins: [],
    supportedTransactions: [],
    supportedExchanges: []
  },


};

const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {

    resetTransactionsList: (state, action) => {
      state.transactionsList = {};
      state.getTransactionsLoading = false;
      state.getTransactionsListError = false;
      state.getTransactionsListSuccess = false;


    },
  },
  
  extraReducers: {
    
    [deleteTransaction.fulfilled]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = true;
      state.getTransactionsListError = false;
    },
    [deleteTransaction.pending]: (state, { payload }) => {
      state.transactionsList = {};
      state.getTransactionsLoading = true;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = false;
    },
    [deleteTransaction.rejected]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = true;
    },
    [addTransaction.fulfilled]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = true;
      state.getTransactionsListError = false;
    },
    [addTransaction.pending]: (state, { payload }) => {
      state.transactionsList = {};
      state.getTransactionsLoading = true;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = false;
    },
    [addTransaction.rejected]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = true;
    },
    [updateTransaction.fulfilled]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = true;
      state.getTransactionsListError = false;
    },
    [updateTransaction.pending]: (state, { payload }) => {
      state.transactionsList = {};
      state.getTransactionsLoading = true;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = false;
    },
    [updateTransaction.rejected]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = true;
    },
    [getTransaction.fulfilled]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = true;
      state.getTransactionsListError = false;
    },
    [getTransaction.pending]: (state, { payload }) => {
      state.transactionsList = {};
      state.getTransactionsLoading = true;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = false;
    },
    [getTransaction.rejected]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = false;
      state.getTransactionsListError = true;
    },
    [getSupportedItems.fulfilled]: (state, { payload }) => {
      state.supportedItems = payload;
    },
    [getSupportedItems.pending]: (state, { payload }) => {
      state.supportedItems = {}
    },
    [getSupportedItems.rejected]: (state, { payload }) => {
      state.transactionsList = payload;
      state.getTransactionsListError = true;
      state.getTransactionsLoading = false;
      state.getTransactionsListSuccess = false;
      state.supportedItems = {}
    }
  },
});

export const { resetTransactionsList, resetUpdateTransaction } = transactionSlice.actions;
export const getTransactionsList = (state) => [state.transactions.transactionsList, state.transactions.getTransactionsListSuccess, state.transactions.getTransactionsListError, state.transactions.getTransactionsLoading];
export const getSupportedTransactionItems = (state) => state.transactions.supportedItems;

export default transactionSlice.reducer;
