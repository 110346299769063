import passwordValidator  from 'password-validator';
const passwordSchema = new passwordValidator();

// Add properties to it
passwordSchema
.is().min(12)                                    // Minimum length 8
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits(1)                                // Must have at least 1 digit
.has().not().spaces()                           // Should not have spaces

export default passwordSchema;