import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import { LoaderTable } from './style'

const TransactionsListLoader = () => {

  return (
    <>
      <LoaderTable>
        <thead>
          <tr>
            {[...Array(13)].map((item, index) => <th key={index}> <Skeleton animation="wave" /></th>)}
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((item, index) => <tr key={index}>
            {[...Array(13)].map((item, index) => <td key={index}> <Skeleton animation="wave" /></td>)}
          </tr>)}
        </tbody>
      </LoaderTable>
    </>

  )
}

export default TransactionsListLoader;