import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch } from "react-redux";

import "./Misc.css";

const Misc = () => {
  const dispatch = useDispatch();
  React.useEffect(() => { }, [])
  const navigate_to = useNavigate();
  const params = new URLSearchParams(window.location.search);
  console.log(params.get("status"));
 
  if (params.get("status")) {

    return (
      <>

        <div style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#e5e5e5',
          paddingTop: '100px'
        }}>
          <Grid container justifyContent="center">
                <Box sx={{
                  margin: '20px', background: '#fff', width: '75%',
                  padding: '25px',
                  borderRadius: '5px'
                }}>
                    <div className="modal_flex">
                        {" "}
                        {(params.get("status") == 'success') && <h2 id="parent-modal-title">Success</h2>}
                        {(params.get("status") == 'failure') && <h2 id="parent-modal-title">Error</h2>}
                    </div>
                    {(params.get("status") == 'success') && <FormHelperText sx={{ color: 'blue' }}>Thank you for choosing the plan. You will receive an email with login details soon.<br />For any queries, please reach out to <a href="mailto:support@defitaxcalc.io">DefitaxCalc support.</a></FormHelperText>}
                    {(params.get("status") == 'failure') && <FormHelperText sx={{ color: 'red' }}>Please try again and if the problem presists, please reach out to <a href="mailto:support@defitaxcalc.io">DefitaxCalc support.</a> </FormHelperText>}
                    <div className="grey_line_modal"></div>
                </Box>        
          </Grid>
        </div>
      </>
    );
  } else {
    return <Navigate to="/" replace={true} />
  }
};

export default Misc;
