import React from "react";
import "./ConfirmEmail.css";
import email from "../../assets/img/email.png";
import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { confirmEmail } from "../../api/userAPI";

const resend = () => {

}

const ConfirmEmail = () => {
  const [activation_status, set_activation_state] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams();
  // let activation_status = 0;
  
  React.useEffect(() => {
    const email = searchParams.get("email");
    const vid = searchParams.get("vid");
    console.log(email + ' ' + vid);
    dispatch(confirmEmail({'email' : email, 'vid' : vid}))
    .then((originalPromiseResult) => {
      console.log(originalPromiseResult)
      if (originalPromiseResult.meta.requestStatus === "fulfilled") {
        // navigate("/login")        
        set_activation_state(1);
      } 
    })
  },[]);    

  if(activation_status) {
    return (
      <div className="wrapper_confirmemail">
        <div className="banner">
          <div className="first_div">
            {" "}
            <img src={email} alt="email" />
          </div>
          <p className="firstp">Account activation</p>
          <p className="secondp">
            Account successfully activated. You will receive one time password soon to <Link to="/login">Log In</Link>.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="wrapper_confirmemail">
        <div className="banner">
          <div className="first_div">
            {" "}
            <img src={email} alt="email" />
          </div>
          <p className="firstp">Account activation</p>
          <p className="secondp">
            Account activation failed, please contact admin for details.
          </p>
        </div>
      </div>
    );    
  }
};

export default ConfirmEmail;
