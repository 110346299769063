import { createSlice } from "@reduxjs/toolkit";
import { getGains,emailGainsReport } from "../api/cryptoGainsAPI";

const initialState = {
    gainsReportLoading: false,
    gainsReportSuccess: false,
    gainsReportError: false,
    gainsReportResponse: {},
    emailGainsReportLoading: false,
    emailGainsReportSuccess: false,
    emailGainsReportError: false,
    emailGainsReportResponse: {},

};

const cryptoGainsSlice = createSlice({
    name: "cryptoGains",
    initialState,
    reducers: {
        resetGainsReport: (state, action) => {
            state.gainsReportLoading = false;
            state.gainsReportSuccess = false;
            state.gainsReportError = false;
            state.gainsReportResponse = {};
        },
        resetEmailGainsReport: (state, action) => {
            state.emailGainsReportLoading = false;
            state.emailGainsReportSuccess = false;
            state.emailGainsReportError = false;
            state.emailGainsReportResponse = {};
        }
    },
    extraReducers: {
        [emailGainsReport.fulfilled]: (state, { payload }) => {
            state.emailGainsReportLoading = false;
            state.emailGainsReportSuccess = true;
            state.emailGainsReportError = false;
            state.emailGainsReportResponse = payload;
        },
        [emailGainsReport.pending]: (state, { payload }) => {
            state.emailGainsReportLoading = true;
            state.emailGainsReportSuccess = false;
            state.emailGainsReportError = false;
            state.emailGainsReportResponse = {};
        },
        [emailGainsReport.rejected]: (state, { payload }) => {
            state.emailGainsReportLoading = false;
            state.emailGainsReportSuccess = false;
            state.emailGainsReportError = true;
            state.emailGainsReportResponse = payload;
        },
        [getGains.fulfilled]: (state, { payload }) => {
            state.gainsReportLoading = false;
            state.gainsReportSuccess = true;
            state.gainsReportError = false;
            state.gainsReportResponse = payload;
        },
        [getGains.pending]: (state, { payload }) => {
            state.gainsReportLoading = true;
            state.gainsReportSuccess = false;
            state.gainsReportError = false;
            state.gainsReportResponse = {};
        },
        [getGains.rejected]: (state, { payload }) => {
            state.gainsReportLoading = false;
            state.gainsReportSuccess = false;
            state.gainsReportError = true;
            state.gainsReportResponse = payload;
        },
    },
});

export const { resetGainsReport ,resetEmailGainsReport} = cryptoGainsSlice.actions;

export const getGainsReport = (state) => [state.cryptoGains.gainsReportResponse, state.cryptoGains.gainsReportSuccess, state.cryptoGains.gainsReportError, state.cryptoGains.gainsReportLoading];
export const getSendGainsReport = (state) => [state.cryptoGains.emailGainsReportResponse, state.cryptoGains.emailGainsReportSuccess, state.cryptoGains.emailGainsReportError, state.cryptoGains.emailGainsReportLoading];

export default cryptoGainsSlice.reducer;
