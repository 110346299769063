import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, REPORTS_SUMMARY_URL, SUPPORTED_EXCHANGES_URL, REPORTS_UPLOAD_URL, REPORTS_VALIDATE_URL, REPORTS_URL } from "./APIConstants"





export const deleteReport = createAsyncThunk('reports/delete', async ({ email, session_id, user_type, cust_id, file_uploaded, exchange }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
    body: undefined
  };


  let url = `${BASE_URL}${REPORTS_URL}?email=${email}&report=${file_uploaded}&exchange=${exchange}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
    "user_type": window.sessionStorage.getItem("user_type"),
    "cust_id": cust_id,
  };

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {
      dispatch(getReportSummary(user_info));
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});



export const validateReportSummary = createAsyncThunk('reports/validate', async ({ email, session_id, user_type, cust_id }, { rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: undefined
  };


  let url = `${BASE_URL}${REPORTS_VALIDATE_URL}?email=${email}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});


export const getSupportedExchanges = createAsyncThunk('supported/exchanges', async ({ email, session_id, user_type, cust_id }, { rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };


  let url = `${BASE_URL}${SUPPORTED_EXCHANGES_URL}?email=${email}`;

  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;

  }
  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
     if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});

export const getReportSummary = createAsyncThunk('reports/summary', async ({ email, session_id, user_type, cust_id }, { dispatch, rejectWithValue, fulfillWithValue }) => {
  dispatch(getSupportedExchanges({ email, session_id, user_type, cust_id }));
  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  let url = `${BASE_URL}${REPORTS_SUMMARY_URL}?email=${email}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }

  try {
    const res = await fetch(url, requestOptions);
    if (res.ok) {
   
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});


export const uploadReportSummary = createAsyncThunk('reports/upload', async ({ email, exchange, file, session_id, user_type, cust_id }, { dispatch, rejectWithValue, fulfillWithValue }) => {

  var myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("x-token-defi", session_id);

  var formdata = new FormData();
  formdata.append("uploaded_file", file, file.path);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  let url = `${BASE_URL}${REPORTS_UPLOAD_URL}?email=${email}&exchange=${exchange}`;
  if (user_type === "Accountant") {
    url = `${url}&cust_id=${cust_id}`;
  }


  try {

    const res = await fetch(url, requestOptions);
    let user_info = {
      "email": window.sessionStorage.getItem("email"),
      "session_id": window.sessionStorage.getItem("session_id"),
      "user_type": window.sessionStorage.getItem("user_type"),
      "cust_id": cust_id,
    };
    if (res.ok) {
      dispatch(validateReportSummary(user_info));
      return fulfillWithValue(await res.json());
    }
    return rejectWithValue(await res.json());
  } catch (err) {
    if (err.message === "Failed to fetch") {
      return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
    }
    return rejectWithValue(err.message);
  }
});
