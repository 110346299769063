export const transactionSchema = {

    'txn_id': 'number',
    'tdt': 'string',
    'tty': 'string',
    'indefi': 'string',
    'incur': 'string',
    'inqty': 'number',
    'oucur': 'string',
    'oudefi': 'string',
    'ouqty': 'number',
    'fvqty': 'number',
    'fefvqty': 'number',
    'is_valid': 'number',
    'review': 'string',

};
