import styled from '@emotion/styled';


export const Table = styled.table`
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
  & tr{
    border-top: 1px solid #ccc;
    margin-top: 10px;
    background: #fafafa;
  }
  & tr td{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-top: 1px solid #ccc;
  color: black;
  text-align: left;
  padding: 10px;
  }
  tr:nth-child(even) {
    background: #fff;
  }
  & tr th{
    color: #848484;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    padding: 10px;
    background: #fff;
  }
`

