import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, GAINS_URL,EMAIL_TAX_GAINS_REPORT_URL } from "./APIConstants"



export const emailGainsReport = createAsyncThunk('sendCryptoGains/reports', async ({ email, startDate, endDate, mode, session_id, user_type, cust_id }, { rejectWithValue, fulfillWithValue }) => {


    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("x-token-defi", session_id);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    let url = `${BASE_URL}${EMAIL_TAX_GAINS_REPORT_URL}?email=${email}&start=${startDate}&end=${endDate}&mode=${mode}&source=Crypto`;
    if (user_type === "Accountant") {
        url = `${url}&cust_id=${cust_id}`;
    }

    try {
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            return fulfillWithValue(await res.json());
        }
        return rejectWithValue(await res.json());
    } catch (err) {
        if (err.message === "Failed to fetch") {
            return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
          }
        return rejectWithValue(err.message);
    }

});


export const getGains = createAsyncThunk('cryptoGains/reports', async ({ email, startDate, endDate, mode, session_id, user_type, cust_id }, { rejectWithValue, fulfillWithValue }) => {


    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("x-token-defi", session_id);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    let url = `${BASE_URL}${GAINS_URL}?email=${email}&start=${startDate}&end=${endDate}&mode=${mode}&source=Crypto`;
    if (user_type === "Accountant") {
        url = `${url}&cust_id=${cust_id}`;
    }

    try {
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            return fulfillWithValue(await res.json());
        }
        return rejectWithValue(await res.json());
    } catch (err) {
        if (err.message === "Failed to fetch") {
            return rejectWithValue("Something Went Wrong. Please Try Again After Some Time");
          }
        return rejectWithValue(err.message);
    }

});
