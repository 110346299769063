import { createSlice } from "@reduxjs/toolkit";
import { getReportSummary, getSupportedExchanges, uploadReportSummary, validateReportSummary, deleteReport } from "../api/exchangeAPI";

const initialState = {
  reportSummary: [],
  reportSummaryLoading: false,
  reportSummaryError: false,
  reportSummarySuccess: false,
  uploadReportLoading: false,
  uploadReportSuccess: false,
  uploadReportError: false,
  uploadReportResponse: {},
  supportedExchanges: [],
};

const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    resetReportSummary: (state, action) => {
      state.reportSummary = [];
      state.reportSummaryLoading = false;
      state.reportSummaryError = false;
      state.reportSummarySuccess = false;
    },
    resetReportUpload: (state, action) => {
      state.uploadReportLoading = false;
      state.uploadReportSuccess = false;
      state.uploadReportError = false;
      state.uploadReportResponse = {};
    }
  },
  extraReducers: {
    [uploadReportSummary.fulfilled]: (state, { payload }) => {
      state.uploadReportResponse = payload;
      state.uploadReportLoading = false;
      state.uploadReportSuccess = true;
      state.uploadReportError = false;

    },
    [uploadReportSummary.pending]: (state, { payload }) => {
      state.uploadReportResponse = {};
      state.uploadReportLoading = true;
      state.uploadReportSuccess = false;
      state.uploadReportError = false;
    },
    [uploadReportSummary.rejected]: (state, { payload }) => {
      state.uploadReportResponse = payload;
      state.uploadReportLoading = false;
      state.uploadReportSuccess = false;
      state.uploadReportError = true;

    },

    [validateReportSummary.fulfilled]: (state, { payload }) => {
      state.reportSummary = payload.uploaded_files_status;
      state.reportSummaryLoading = false;
      state.reportSummaryError = false;
      state.reportSummarySuccess = true;
    },
    [validateReportSummary.pending]: (state, { payload }) => {
      state.reportSummary = [];
      state.reportSummaryLoading = true;
      state.reportSummaryError = false;
      state.reportSummarySuccess = false;
    },
    [validateReportSummary.rejected]: (state, { payload }) => {
      state.reportSummary = payload
      state.reportSummaryLoading = false;
      state.reportSummaryError = true;
      state.reportSummarySuccess = false;
    },

    [getReportSummary.fulfilled]: (state, { payload }) => {
      state.reportSummary = payload.uploaded_files_status;
      state.reportSummaryLoading = false;
      state.reportSummaryError = false;
      state.reportSummarySuccess = true;
    },
    [getReportSummary.pending]: (state, { payload }) => {
      state.reportSummary = [];
      state.reportSummaryLoading = true;
      state.reportSummaryError = false;
      state.reportSummarySuccess = false;
    },
    [getReportSummary.rejected]: (state, { payload }) => {
      state.reportSummary = payload
      state.reportSummaryLoading = false;
      state.reportSummaryError = true;
      state.reportSummarySuccess = false;
    },
    [getSupportedExchanges.fulfilled]: (state, { payload }) => {
      state.supportedExchanges = payload;
    },
    [getSupportedExchanges.pending]: (state, { payload }) => {
      state.supportedExchanges = []
    },
    [getSupportedExchanges.rejected]: (state, { payload }) => {
      state.reportSummary = payload
      state.reportSummaryLoading = false;
      state.reportSummaryError = true;
      state.reportSummarySuccess = false;
      state.supportedExchanges = []
    },
    [deleteReport.fulfilled]: (state, { payload }) => {
      state.reportSummary = [];
      state.reportSummaryLoading = true;
      state.reportSummaryError = false;
      state.reportSummarySuccess = false;
    },
    [deleteReport.pending]: (state, { payload }) => {
      state.reportSummary = [];
      state.reportSummaryLoading = true;
      state.reportSummaryError = false;
      state.reportSummarySuccess = false;
    },
    [deleteReport.rejected]: (state, { payload }) => {
      state.reportSummary = payload
      state.reportSummaryLoading = false;
      state.reportSummaryError = true;
      state.reportSummarySuccess = false;
    },

  },
});

export const { resetReportUpload, resetReportSummary } = exchangeSlice.actions;

export const getReportUploadStatus = (state) => [state.exchange.uploadReportResponse, state.exchange.uploadReportSuccess, state.exchange.uploadReportError, state.exchange.uploadReportLoading];

export const getExchangeReportSummary = (state) => [state.exchange.reportSummary, state.exchange.reportSummarySuccess, state.exchange.reportSummaryError, state.exchange.reportSummaryLoading];
export const getSupportedExchangesList = (state) => state.exchange.supportedExchanges;

export default exchangeSlice.reducer;
