import React, { Component } from 'react';
import "./Header.css";
import user from "../../assets/img/User.png"

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

import signout from "../../assets/img/signout.png"
import logo from "../../assets/img/Logo2.png";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import square from "../../assets/img/square.png"
import two from "../../assets/img/2.png";
import three from "../../assets/img/3.png";
import four from "../../assets/img/4.png";
import five from "../../assets/img/5.png";
import arr from "../../assets/img/arr.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../api/userAPI";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getSelectedCustomer, resetselectCustomer } from "../../features/CustomerSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  let user_info = {
    "email": window.sessionStorage.getItem("email"),
    "session_id": window.sessionStorage.getItem("session_id"),
    "user_type": window.sessionStorage.getItem("user_type")
  };
  const customer = useSelector(getSelectedCustomer);
  const onLogout = () => {
    let user_info = { "email": window.sessionStorage.getItem("email"), "session_id": window.sessionStorage.getItem("session_id") };

    dispatch(resetselectCustomer());
    dispatch(logoutUser(user_info))
      .then((originalPromiseResult) => {
        window.sessionStorage.clear();
        window.location.replace("https://defitaxcalc.io/");
      });


  }

  const activeClassName = 'activeLink';
  return (
    <header className="header">

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box>  <img src={logo} alt="" /></Box>
        </Grid>
        <Grid item xs={7}>
          <Box className='activeLinkContainer'>
            {user_info.user_type === "Accountant" && <NavLink className={({ isActive }) =>
              isActive ? activeClassName : undefined
            } to="/clients"> <img className="link_menu_img" src={five} alt="" />Clients</NavLink>}
            {((user_info.user_type === "Accountant" && customer !== "") || user_info.user_type !== "Accountant") && <>

              <NavLink
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
                to="/records"> <img className="link_menu_img" src={three} alt="" />Records</NavLink>
              <NavLink className={({ isActive }) =>
                isActive ? activeClassName : undefined
              } to="/transactions"> <img className="link_menu_img" src={two} alt="" />Transactions</NavLink>
              <NavLink className={({ isActive }) =>
                isActive ? activeClassName : undefined
              } to="/cryptogains"> <img className="link_menu_img" src={four} alt="" />Crypto Gains Report</NavLink>
              {/* <NavLink className={({ isActive }) =>
                isActive ? activeClassName : undefined
              } to="/invertory"> <img className="link_menu_img" src={five} alt="" />Inventory</NavLink> */}
            </>}

          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex"
          }}>

            <Stack direction="row" spacing={2}>

              <div>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? 'composition-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >  <p className="user_p">Hello {window.sessionStorage.getItem("firstname") + ' ' + window.sessionStorage.getItem("lastname")}</p>
                  <img src={user} alt="" />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={() => {navigate('/changepassword')}}>Change Password</MenuItem>

                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Stack>


            <div className="small_line"></div>
            <button className="sign_out" onClick={onLogout}>Logout <img src={signout} /> </button>
          </Box>
        </Grid>

      </Grid>

    </header>
  )
}

export default Header