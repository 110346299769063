import { createSlice } from "@reduxjs/toolkit";
import { getCustomersList, addCustomer, deleteCustomer } from "../api/customerAPI";

const initialState = {
    customersList: [],
    customersListLoading: false,
    customersListError: false,
    customersListSuccess: false,

    addCustomer: "",
    addCustomerLoading: false,
    addCustomerError: false,
    addCustomerSuccess: false,

    selectedCustomer: "",




};

const CustomerSlice = createSlice({
    name: "exchange",
    initialState,
    reducers: {

        resetselectCustomer: (state, { payload }) => {
            state.selectedCustomer = "";
        },


        selectCustomer: (state, { payload }) => {
            state.selectedCustomer = payload;
        },

        resetAddCustomer: (state, action) => {
            state.addCustomer = "";
            state.addCustomerLoading = false;
            state.addCustomerError = false;
            state.addCustomerSuccess = false;
        },
        resetCustomersList: (state, action) => {
            state.customersList = [];
            state.customersListLoading = false;
            state.customersListError = false;
            state.customersListSuccess = false;
        },
    },
    extraReducers: {
        [getCustomersList.fulfilled]: (state, { payload }) => {
            state.customersList = payload;
            state.customersListLoading = false;
            state.customersListError = false;
            state.customersListSuccess = true;

        },
        [getCustomersList.pending]: (state, { payload }) => {
            state.customersList = [];
            state.customersListLoading = true;
            state.customersListError = false;
            state.customersListSuccess = false;
        },
        [getCustomersList.rejected]: (state, { payload }) => {
            state.customersList = payload;
            state.customersListLoading = false;
            state.customersListError = true;
            state.customersListSuccess = false;
        },
        [addCustomer.fulfilled]: (state, { payload }) => {

            state.addCustomer = payload;
            state.addCustomerLoading = false;
            state.addCustomerError = false;
            state.addCustomerSuccess = true;

        },
        [addCustomer.pending]: (state, { payload }) => {
            state.addCustomer = "";
            state.addCustomerLoading = true;
            state.addCustomerError = false;
            state.addCustomerSuccess = false;
        },
        [addCustomer.rejected]: (state, { payload }) => {
            state.addCustomer = payload;
            state.addCustomerLoading = false;
            state.addCustomerError = true;
            state.addCustomerSuccess = false;
        },

        [deleteCustomer.fulfilled]: (state, { payload }) => {
            state.customersList = [];
            state.customersListLoading = true;
            state.customersListError = false;
            state.customersListSuccess = false;

        },
        [deleteCustomer.pending]: (state, { payload }) => {
            state.customersList = [];
            state.customersListLoading = true;
            state.customersListError = false;
            state.customersListSuccess = false;
        },
        [deleteCustomer.rejected]: (state, { payload }) => {
            state.customersList = payload;
            state.customersListLoading = false;
            state.customersListError = true;
            state.customersListSuccess = false;
        },


    },
});

export const { resetCustomersList, resetAddCustomer, selectCustomer,resetselectCustomer } = CustomerSlice.actions;

export const getAccountantCustomersList = (state) => [state.customer.customersList, state.customer.customersListSuccess, state.customer.customersListError, state.customer.customersListLoading];
export const addCustomerStatus = (state) => [state.customer.addCustomer, state.customer.addCustomerSuccess, state.customer.addCustomerError, state.customer.addCustomerLoading];
export const getSelectedCustomer = (state) => state.customer.selectedCustomer;




export default CustomerSlice.reducer;
